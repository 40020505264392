<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "pollaPatasIndex",
  data: () => ({}),
};
</script>

<style>
</style>